import React, { useEffect, useState } from "react";
import "../styles/Contact.css";
import useFirestore from "../hooks/useFirestore";
import { Helmet } from "react-helmet";
import axios from "axios";

// import Map from "../assets/img/colorkaarmap.jpg";

// Icons
import { AiOutlineMail } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
import { TiTick } from "react-icons/ti";
const Contact = () => {
  const [document, setDocument] = useState([]);
  const { docs } = useFirestore("instagram");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [sentShow, setSentShow] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [submit, setSubmit] = useState(false);
  // useEffect(() => {
  //   var instagramdata = sessionStorage.getItem("instagramdata");
  //   setDocument([]);

  //   if (instagramdata != null) {
  //     const parse = JSON.parse(instagramdata);

  //     parse.forEach((doc) => {
  //       setDocument((prevState) => [...prevState, doc]);
  //     });
  //   } else {
  //     if (docs.length > 0) {
  //       // setTimeout(() => {
  //       docs[0]?.schema.forEach((doc) => {
  //         setDocument((prevState) => [...prevState, doc]);
  //       });
  //       const stringify = JSON.stringify(docs[0].schema);
  //       sessionStorage.setItem("instagramdata", stringify);
  //       // }, 2000);
  //     }
  //   }
  // }, [docs]);
  useEffect(() => {
    async function updateInstagramFeed() {
      try {
        // Fetch data from the new API
        const data = {
          userid: 3022389772,
          documentid: "colorkaar",
          numberofpost: 6,
          bucketname: "colorkaar-u",
        };

        const response = await axios.post(`${BASE_URL}instagram`, data);
        const fetchedData = response?.data.data;
        setDocument(fetchedData); // Set data for feedClub (UI display)

        // Store in session storage
        const stringify = JSON.stringify(fetchedData);
        sessionStorage.setItem("instagramdata", stringify);
      } catch (error) {
        console.error(
          "New API request failed, falling back to Firestore",
          error
        );

        // If the new API fails, fall back to old logic using Firestore
        const instagramdata = sessionStorage.getItem("instagramdata");
        setDocument([]); // Clear the previous state

        if (instagramdata != null) {
          // If session storage has data, use it
          const parsedData = JSON.parse(instagramdata);
          setDocument(parsedData);
        } else if (docs.length > 0) {
          // Use Firestore data if no session data exists
          const firestoreData = docs[0]?.schema || [];
          setDocument(firestoreData);

          const firestoreStringify = JSON.stringify(firestoreData);
          sessionStorage.setItem("instagramdata", firestoreStringify);
        }
      }
    }

    updateInstagramFeed();
  }, [docs,BASE_URL]);

  const sendMail = (e) => {
    e.preventDefault();
    if (name !== "" && email !== "") {
      //send mail
      setSubmit(false);
      setSentShow(true);

      fetch(BASE_URL + "mail", {
        method: "POST",
        headers: {
          accept: "application.json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          message,
        }),
      })
        .then((response) => {
          //nothing
          // setSentShow(true);
        })
        .catch((error) => {
          setSentShow(false);
        });
    } else {
      setSubmit(true);
    }
  };
  const movetoinstagram = () => {
    window.open("https://www.instagram.com/colorkaar/", "_blank").focus();
  };
  return (
    <div className="contact-main">
      <Helmet>
        <title>Colorkaar | Contact</title>
        <meta
          name="description"
          content="Colorkaar - Color Grading Boutique Commercials , Films and Webseries Color Grading Services"
        />
      </Helmet>
      <br />
      <div className="contact-about">
        <div className="contact-about-form">
          <h2>GET IN TOUCH</h2>
          {!sentShow && (
            <>
              {" "}
              <div className="contact-about-input">
                <input
                  autoComplete="off"
                  type="text"
                  id="colorkaarname"
                  onChange={(e) => setName(e.target.value)}
                  name="colorkaarname"
                  placeholder="Name"
                  className={submit && name.length === 0 && "inputerror"}
                />
              </div>{" "}
              <div className="contact-about-input">
                <input
                  autoComplete="off"
                  type="email"
                  id="colorkaaremail"
                  onChange={(e) => setEmail(e.target.value)}
                  name="colorkaaremail"
                  placeholder="Email"
                  className={submit && email.length === 0 && "inputerror"}
                />
              </div>
              <div className="contact-about-input">
                <textarea
                  autoComplete="off"
                  type="text"
                  id="colorkaarmessage"
                  onChange={(e) => setMessage(e.target.value)}
                  name="colorkaarmessage"
                  placeholder="Write your message..."
                ></textarea>
              </div>
              <div className="contact-about-button">
                <button type="button" onClick={(e) => sendMail(e)}>
                  Submit
                </button>
              </div>{" "}
            </>
          )}
          {sentShow && (
            <div className="contact-about-form-success">
              <div>
                <TiTick size={"40px"} />
                <span id="form-sent">Sent!</span>
              </div>
            </div>
          )}
          <br /> <br />
          <div className="contact-about-withsocial">
            <span>Don’t Like Forms ?</span>
            <br />
            <a
              href="mailto:contact@colorkaar.com"
              target="_blank"
              rel="noreferrer"
            >
              <AiOutlineMail /> contact@colorkaar.com
            </a>{" "}
            <a
              href="https://wa.me/+918872400624"
              target="_blank"
              rel="noreferrer"
            >
              <BsWhatsapp /> +91-8872400624
            </a>
            <br />
            <br />
          </div>
        </div>
        <div className="contact-about-instagram">
          <div className="contact-map">
            <a href="https://maps.app.goo.gl/Ri5YdJNA9Y2zeh6F6">
              {" "}
              <img
                src="/images/colorkaarMapByUday.png"
                alt="colorkaar-map-mumbai"
              />
              <span>
                {/* C-409, C Wing, 4th Floor, Shreedham Splendour, New Link Rd,
                Anand Nagar, Oshiwara Andheri West, Mumbai, Maharashtra 400102 */}
                4th Floor, Shreedham Splendour, New Link Rd, Anand Nagar,
                Oshiwara Andheri West, Mumbai
              </span>
            </a>
          </div>
          {/* <span onClick={() => movetoinstagram()}>
            Be the part of our instagram fam!
            <br />
            @colorkaar
          </span>
          <div onClick={() => movetoinstagram()}>
            <br></br>
            <div className="contact-about-instagram-img">
              {document.length > 0 &&
                document.map((doc, key) => (
                  <>
                    {" "}
                    <div id={key}>
                      <img src={doc.url} alt={doc.id} />
                    </div>
                  </>
                ))}{" "}
            </div>
          </div>
          <button onClick={() => movetoinstagram()}>Follow</button> */}
        </div>
      </div>{" "}
      {/* <div className="contact-map">
        <h2>SITEMAP</h2>
        <a href="https://maps.app.goo.gl/5a4NNdst6vnJyNYo7">
          {" "}
          <img src={Map} alt="colorkaar-map-mumbai" />
          <span>
            C-409, C Wing, 4th Floor, Shreedham Splendour, New Link Rd, Anand
            Nagar, Oshiwara Andheri West, Mumbai, Maharashtra 400102
          </span>
        </a>
      </div> */}
      <div className="contact-about-mobile">
        <div className="contact-about-instagram-mobile">
          <div className="contact-map">
            <span style={{ margin: "6px" }}>
              4th Floor, Shreedham Splendour, Andheri West, Mumbai
            </span>
            <a href="https://maps.app.goo.gl/5a4NNdst6vnJyNYo7">
              {" "}
              <img
                src="/images/colorkaarMapByUday.png"
                alt="colorkaar-map-mumbai"
              />
            </a>
          </div>
          <span onClick={() => movetoinstagram()}>
            Be the part of our instagram fam!
            <br />
            @colorkaar
          </span>
          <div>
            <br />
            <div className="contact-about-instagram-img">
              {document.length > 0 &&
                document.slice(0, 6).map((doc, key) => (
                  <>
                    {" "}
                    <div id={key}>
                      <img src={doc.url} alt={doc.id} />
                    </div>
                  </>
                ))}{" "}
            </div>
          </div>
          <button onClick={() => movetoinstagram()}>Follow</button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
